import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../layout';
import SEO from '../components/Seo/Seo';
import PostCard from '../components/PostCard/PostCard';
import ContentCard from '../components/ContentCard/ContentCard';

const IndexPage = ({ data: { allWordpressPost, allWordpressWpJournal } }) => (
  <Layout>
    <SEO title="Home" />
    <div className="home-page">
      <div className="content">
        <div className="items">
          {allWordpressWpJournal.edges.map(journal => (
            <ContentCard
              key={journal.node.id}
              path={journal.node.path}
              title={journal.node.childWordPressAcfHeader.title}
              image={journal.node.featured_media}
            />
          ))}
        </div>
        <div className="actions">
          <Link className="button secondary" to="/journals">
            Alle journals
          </Link>
        </div>
      </div>

      <div className="sidebar">
        <div className="items">
          {allWordpressPost.edges.map(post => {
            const getExcerpt = () => {
              if (post.node.childWordPressAcfIntro) {
                return post.node.childWordPressAcfIntro;
              }

              if (post?.node?.childWordPressAcfParagraph?.text) {
                return post.node.childWordPressAcfParagraph.text;
              }

              return '';
            };

            return (
              <PostCard
                key={post.node.id}
                date={post.node.date}
                title={post.node.title}
                excerpt={getExcerpt()}
                path={post.node.path}
                categories={post.node.categories}
              />
            );
          })}
        </div>
        <div className="actions">
          <Link className="button secondary" to="/posts">
            Alle nieuwsitems
          </Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  {
    allWordpressWpJournal(limit: 3, sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          path
          childWordPressAcfHeader {
            title
          }
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 900, maxHeight: 600) {
                  src
                  srcSet
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
    allWordpressPost(limit: 6, sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          date(formatString: "DD MMMM YYYY", locale: "nl_NL")
          title
          path
          childWordPressAcfIntro {
            text
          }
          childWordPressAcfParagraph {
            text
          }
          categories {
            id
            name
            path
          }
        }
      }
    }
  }
`;
