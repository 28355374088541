import React from 'react';
import { Link } from 'gatsby';
import CardWrapper from './ContentCard.style';

const ContentCard = ({ path, title, image }) => (
  <CardWrapper className="content-card">
    <Link to={path}>
      <h3
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      {image && image.localFile && (
        <picture>
          <source
            srcSet={image.localFile.childImageSharp.fluid.srcSetWebp}
            type="image/webp"
          />
          <source
            srcSet={image.localFile.childImageSharp.fluid.srcSet}
            type="image/jpeg"
          />
          <img
            src={image.localFile.childImageSharp.fluid.src}
            alt={image.alt_text}
          />
        </picture>
      )}
    </Link>
  </CardWrapper>
);

export default ContentCard;
