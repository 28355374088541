import styled from 'styled-components';
import { themeGet } from 'styled-system';

const CardWrapper = styled.div`
  margin-bottom: 25px;

  .meta {
    display: flex;
    flex-wrap: wrap;
    color: ${themeGet('color.4')};
    font-family: 'Inter', sans-serif;
    margin-bottom: 3px;
    a {
      color: ${themeGet('color.4')};
      font-weight: 700;
      margin-right: 0.6em;
    }
  }

  .category {
    margin: 0;
    font-size: 15px;
    list-style: none;
    li {
      a {
        font-weight: 600;
      }
    }
  }

  h4 {
    margin: 0 0 0.3em;
    line-height: 30px;
  }

  .excerpt {
    padding-bottom: 3px;
    font-size: 16px;
    line-height: 26px;
  }
`;

export default CardWrapper;
