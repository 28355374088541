import React from 'react';

const TextExcerpt = ({ text, truncateText, excerptWord }) => {
  const word = excerptWord || 25;

  if (!text) return <div className="excerpt"></div>;

  const excerpt =
    text &&
    text.length &&
    text
      .split(' ')
      .slice(0, word)
      .join(' ')
      .replace(/(<([^>]+)>)/gi, '');
  const afterExcerpt = truncateText || '...';

  return (
    <div className="excerpt">
      {excerpt}
      {afterExcerpt}
    </div>
  );
};

export default TextExcerpt;
