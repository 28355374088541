import styled from 'styled-components';

const CardWrapper = styled.div`
  a {
    box-sizing: border-box;
    color: #fff;
    transition: 0.2s;
    transition-timing-function: ease-in-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background: linear-gradient(
        -180deg,
        rgba(15, 15, 15, 0) 60%,
        rgba(15, 15, 15, 0.7)
      );
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    &:hover {
      &::before {
        opacity: 0;
      }
    }

    h3 {
      position: absolute;
      z-index: 3;
      left: 25px;
      bottom: 50px;
      width: calc(80% - 50px);
      font-weight: 400;
    }

    picture {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;

export default CardWrapper;
