import React from 'react';
import { Link } from 'gatsby';
import { FaAngleDoubleRight } from 'react-icons/fa';
import TextExcerpt from '../TextExcerpt/TextExcerpt';
import CardWrapper from './PostCard.style';

const PostCard = ({ date, title, excerpt, path, categories }) => (
  <CardWrapper className="post-card">
    <div className="meta">
      {categories && (
        <ul className="category">
          {categories.map(item => (
            <li key={item.id}>
              <Link to={item.path}>{item.name},</Link>
            </li>
          ))}
        </ul>
      )}

      {date && <span className="date">{date}</span>}
    </div>
    {title && <h4>{title}</h4>}
    {excerpt && <TextExcerpt text={excerpt} />}
    {path && (
      <Link className="readmore" to={path}>
        <FaAngleDoubleRight />
        <span>Lees verder</span>
      </Link>
    )}
  </CardWrapper>
);

export default PostCard;
